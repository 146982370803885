var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message"},[_c('div',{staticClass:"message__author"},[_c('div',{staticClass:"message__author__avatar d-none d-sm-block"},[_c('img',{attrs:{"src":_vm.object.character.avatar
            ? _vm.$url + _vm.object.character.avatar
            : _vm.user.avatar.replace('http://', 'https://'),"alt":""}})]),_c('div',{staticClass:"message__author__info d-none d-sm-block"},[_c('div',{staticClass:"message__author__info__name",domProps:{"innerHTML":_vm._s(_vm.object.character.first_name + ' ' + _vm.object.character.last_name)}}),(_vm.object.character.social_role)?_c('div',{staticClass:"message__author__info__role",domProps:{"innerHTML":_vm._s(_vm.object.character.social_role)}}):_vm._e(),(_vm.user.male || !_vm.object.female_text)?_c('div',{staticClass:"message__content",domProps:{"innerHTML":_vm._s(
          _vm.object.text
            .replace(/%name%/g, _vm.user.first_name)
            .replace(/%surname%/g, _vm.user.last_name)
            .replace(/%points%/g, _vm.pageData.user_progress.points)
            .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
        )}}):_c('div',{staticClass:"message__content",domProps:{"innerHTML":_vm._s(
          _vm.object.female_text
            .replace(/%name%/g, _vm.user.first_name)
            .replace(/%surname%/g, _vm.user.last_name)
            .replace(/%points%/g, _vm.pageData.user_progress.points)
            .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
        )}}),(_vm.button)?_c('FormButton',{attrs:{"loading":_vm.loading,"inactive":_vm.loading,"text":_vm.simInfo.button_next_text ? _vm.simInfo.button_next_text : 'Далее',"color":"primary","size":"md"},on:{"click":_vm.nextStory}}):_vm._e()],1),_c('div',{staticClass:"message__author__info d-block d-sm-none"},[_c('img',{staticClass:"d-table d-sm-none message__author__info__avatar_mob float-left mr-3",attrs:{"src":_vm.$url + _vm.object.character.avatar,"alt":""}}),_c('div',{staticClass:"author_info_group"},[_c('div',{staticClass:"message__author__info__name",domProps:{"innerHTML":_vm._s(_vm.object.character.first_name + ' ' + _vm.object.character.last_name)}}),(_vm.object.character.social_role)?_c('div',{staticClass:"message__author__info__role",domProps:{"innerHTML":_vm._s(
            _vm.object.character.social_role == 'Пользователь игрока'
              ? _vm.simInfo.you_text
                ? _vm.simInfo.you_text
                : 'это вы'
              : _vm.object.character.social_role
          )}}):_vm._e()]),(_vm.user.male || !_vm.object.female_text)?_c('div',{staticClass:"message__content",domProps:{"innerHTML":_vm._s(
          _vm.object.text
            .replace(/%name%/g, _vm.user.first_name)
            .replace(/%surname%/g, _vm.user.last_name)
            .replace(/%points%/g, _vm.pageData.user_progress.points)
            .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
        )}}):_c('div',{staticClass:"message__content",domProps:{"innerHTML":_vm._s(
          _vm.object.female_text
            .replace(/%name%/g, _vm.user.first_name)
            .replace(/%surname%/g, _vm.user.last_name)
            .replace(/%points%/g, _vm.pageData.user_progress.points)
            .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
        )}}),(_vm.button)?_c('FormButton',{attrs:{"loading":_vm.loading,"inactive":_vm.loading,"text":_vm.simInfo.button_next_text ? _vm.simInfo.button_next_text : 'Далее',"color":"primary","size":"md"},on:{"click":_vm.nextStory}}):_vm._e()],1)]),(!_vm.button)?_c('div',{staticClass:"message__separate"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }