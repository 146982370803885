<template>
  <div
    class="author-answer"
    v-html="text.replace(/https:\/\/newapi.mysimulator.ru/g, $url)"
  ></div>
</template>

<script>
import postscribe from "postscribe";
export default {
  props: ["text", "object"],
  mounted() {
    if (
      this.object.script_text &&
      this.object.script_id &&
      this.object.script_id != "" &&
      this.object.script_text != ""
    ) {
      let str = this.object.script_text;
      postscribe("#" + this.object.script_id, str);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vars.scss";

.author-answer {
  margin-top: 30px;
  // border-left: solid 4px $primary_color;
  padding-left: 20px;
}
</style>
