var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.object)?_c('div',{staticClass:"message"},[_c('div',{staticClass:"message-answer",class:_vm.direction},[_c('div',{staticClass:"message__author d-none d-sm-flex"},[_c('div',{staticClass:"message__author__avatar",class:_vm.direction},[(!_vm.object.is_current_user)?_c('img',{attrs:{"src":_vm.object.character.avatar
              ? _vm.$url + _vm.object.character.avatar
              : _vm.user.avatar.replace('http://', 'https://'),"alt":""}}):_c('img',{attrs:{"src":_vm.user.avatar.replace('http://', 'https://'),"alt":""}})])]),_c('div',{staticClass:"message__content w-100 w-sm-80"},[_c('div',{staticClass:"message__content__char"},[(!_vm.object.is_current_user)?[_c('img',{staticClass:"d-table d-sm-none char_img_mobile",attrs:{"src":_vm.object.character.avatar
                ? _vm.$url + _vm.object.character.avatar
                : _vm.user.avatar.replace('http://', 'https://'),"alt":""}}),_c('div',{staticClass:"message__content__char__name"},[_vm._v(" "+_vm._s(_vm.object.character.first_name)+" "+_vm._s(_vm.object.character.last_name)),(_vm.object.character.social_role)?_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(", "+_vm._s(_vm.object.character.social_role))]):_vm._e()])]:[_c('img',{staticClass:"d-table d-sm-none char_img_mobile",attrs:{"src":_vm.user.avatar.replace('http://', 'https://'),"alt":""}}),_c('div',{staticClass:"message__content__char__name"},[_vm._v(" "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(", "+_vm._s(_vm.object.forced_role ? _vm.object.forced_role : _vm.simInfo.you_text ? _vm.simInfo.you_text : "это вы"))])])],(_vm.object.type === 'open_comment')?_c('div',{staticClass:"message__content__char__score"},[_c('StarRating',{attrs:{"star-size":20,"active-color":"#FFCF5C","show-rating":false,"rating":_vm.object.score ? _vm.object.score : 0,"read-only":true}})],1):_vm._e()],2),(_vm.user.male || !_vm.object.female_text)?_c('div',{staticClass:"message__content__text",class:{ 'mb-3': _vm.object.files, 'pre-line': _vm.is_user },domProps:{"innerHTML":_vm._s(
          (_vm.object.text ? _vm.object.text : _vm.object.answer)
            .replace(/%name%/g, _vm.user.first_name)
            .replace(/%surname%/g, _vm.user.last_name)
            .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
            .replace(/%points%/g, _vm.pageData.user_progress.points)
        )}}):_c('div',{staticClass:"message__content__text",class:{ 'mb-3': _vm.object.files, 'pre-line': _vm.is_user },domProps:{"innerHTML":_vm._s(
          _vm.object.female_text
            .replace(/%name%/g, _vm.user.first_name)
            .replace(/%surname%/g, _vm.user.last_name)
            .replace(/https:\/\/newapi.mysimulator.ru/g, _vm.$url)
            .replace(/%points%/g, _vm.pageData.user_progress.points)
        )}}),(_vm.object.files)?_c('FileList',{attrs:{"uploaded":true,"files":_vm.object.files}}):_vm._e(),(_vm.need_score && !_vm.is_char)?_c('div',{staticClass:"mt-2"},[_c('StarRating',{attrs:{"star-size":30,"active-color":"#FFCF5C","show-rating":false,"rating":_vm.object.score ? _vm.object.score : 0},on:{"rating-selected":function($event){return _vm.$emit('score-comment', _vm.rating)}},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}})],1):(_vm.need_score && _vm.is_char)?_c('div',{staticClass:"mt-2"},[_c('b-btn',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$emit('score-comment')}}},[_vm._v("Просмотрено")])],1):_vm._e(),_c('div',{staticClass:"message__content__triangle d-none d-sm-block",class:_vm.direction}),(_vm.button && _vm.loaded)?_c('FormButton',{staticStyle:{"margin-bottom":"5px"},attrs:{"loading":_vm.loading,"inactive":_vm.loading,"text":_vm.simInfo.button_next_text ? _vm.simInfo.button_next_text : 'Далее',"size":"medium-large","color":"primary"},on:{"click":_vm.nextStory}}):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }